import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { filterUnitsByName } from './armorySlice'

export default function UnitFilter (props) {
  const dispatch = useDispatch()
  const state = useSelector(state => state.armory)
  const nameFilter = state.current.filter.name?.fuzzy

  return (
    <div>
      <input
        value={nameFilter || ''}
        onChange={e => {
          dispatch(filterUnitsByName(e.target.value))
        }}
        placeholder='Search...'
      />
    </div>
  )
}
