import React from 'react'
import { useDispatch } from 'react-redux'

import HashtagString from '../../component/hashtag-string'
import { showUnit } from '../armory/armorySlice'

function Name ({ unit }) {
  const dispatch = useDispatch()

  function onClick () {
    dispatch(showUnit(unit))
  }

  return (
    <div className='name' onClick={onClick}>
      <HashtagString text={unit.name} />
    </div>
  )
}

function printTransport (f, u) {
  if ((f.code === 12 && u.transports.length < 2) || u.transports.length === 0) {
    return null
  }

  return <Name unit={u.transports[0]} />
}

function Stripes ({ count }) {
  let d = 'M 2,29 l 10,-7 l 10,7'
  for (let i = 1; i <= count; i++) {
    d += ' M 2,' + (29 - i * 4) + ' l 10,-7 l 10,7'
  }
  return (
    <div className='stripes'>
      <svg width='28' height='31'>
        <path
          d={d}
          fill='none'
          stroke='#ffffff'
          strokeWidth={1}
          strokeOpacity={1}
        />
      </svg>
    </div>
  )
}

export default function Unit ({ factory, unit }) {
  if (!unit) {
    return null
  }
  const cost = unit.transports.reduce(
    (acc, tr) => acc + '+' + tr.cost,
    unit.unit.cost
  )

  return (
    <div className='unit'>
      <img src={unit.unit.textureURL} alt={unit.unit.name} />
      <Name unit={unit.unit} />
      {printTransport(factory, unit)}
      <div className='cost'>{cost}</div>
      <div className='count'>{unit.count}</div>
      <img
        className='flag'
        src={window.wgImages.flags.normal[unit.unit.country]}
        alt={unit.unit.country}
      />
      <Stripes count={unit.veterancy} />
    </div>
  )
}
