import { createSlice, combineReducers } from '@reduxjs/toolkit'

import config from '../package.alias.json'

import armoryReducer from '../features/armory/armorySlice'
import deckReducer from '../features/decks/deckSlice'
import displayReducer from '../features/display/displaySlice'
import everythingReducer from '../features/everything/everythingSlice'
import replayReducer from '../features/replays/replaySlice'
import versionReducer from '../features/version/versionSlice'

const versionSlice = createSlice({
  name: 'ver',
  initialState: { version: config.version },
  reducers: {
    setVersion () {}
  }
})

const rootReducer = combineReducers({
  armory: armoryReducer,
  deck: deckReducer,
  display: displayReducer,
  everything: everythingReducer,
  replays: replayReducer,
  ver: versionSlice.reducer,
  version: versionReducer
})

export default rootReducer
