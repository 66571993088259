import { isLoading } from './suspense'

export function currentPage (state) {
  if (!state.loaded) {
    return 0
  }
  return Math.floor(state.loaded.after / state.loaded.first)
}

export function pageCount (state, count) {
  if (!count) {
    return 0
  }
  return Math.ceil(count / state.current.first)
}

export function canPreviousPage (state, error, count) {
  return !isLoading(state) && !error && count && state.current.after > 0
}

export function canNextPage (state, error, count) {
  return (
    !isLoading(state) &&
    !error &&
    count > state.current.after + state.current.first
  )
}

export const nextPage = (state, count, goToPage) => dispatch => {
  dispatch(goToPage({ page: currentPage(state) + 1, count }))
}

export const previousPage = (state, count, goToPage) => dispatch => {
  dispatch(goToPage({ page: currentPage(state) - 1, count }))
}
