const gql = x => x[0]

const query = gql`
  fragment unitProps on Unit {
    id
    version
    name
    country
    cost
    availability
    maxCards
    size
    ecm
    isPrototype
    deckTypes {
      name
    }
    health
    stealth
    speed
    altitude
    altitudeMin
    radius
    autonomy
    fuel
    armor {
      front
      side
      back
      top
    }
    factory {
      code
      name
    }
    weapons {
      id
      version
      turretId
      turretRotation
      name
      accuracy
      stabilizer
      dispersionAngle
      dispersionMaxRange
      dispersionMinRange
      dispersionRandom
      dispersionCorrection
      autoloader
      ap
      heat
      he
      aoe
      smoke
      heRange
      suppressRange
      suppress
      ammo
      salvoLength
      aimTime
      salvoReload
      shotReload
      multiple
      maxRange {
        land
        air
        plane
        ship
        missile
      }
      minRange {
        land
        air
        plane
        ship
        missile
      }
      noise1
      noise2
      isCluster
      isRadar
      isAntiRadar
      isFireAndForget
      maxSpeed
      maxAcceleration
      correctionTime
      supplyCost
      textureURL
      type
    }
    optics {
      identifyChance
      identifyTime
      range {
        land
        air
        plane
        ship
      }
      strength {
        land
        air
        plane
        ship
      }
    }
    supply
    textureURL
    training
    isAShMTarget
  }
`

module.exports = query
