import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setDisplayType, goBack } from './everythingSlice'

export const Link = props => {
  const dispatch = useDispatch()

  const onClick = e => {
    const type = props.to
    const param = props.param
    dispatch(setDisplayType({ type, param }))
  }

  return (
    <button className='link' onClick={onClick}>
      {props.children}
    </button>
  )
}

export const BackLink = props => {
  const dispatch = useDispatch()
  const display = useSelector(state => state.everything.display)

  const onClick = e => {
    dispatch(goBack())
  }

  if (!display.prev) {
    return null
  }

  return (
    <button className='link' onClick={onClick}>
      {props.children}
    </button>
  )
}

export const Switch = props => {
  const displayType = useSelector(state => state.everything.display.type)
  return props.children.reduce((acc, curr) => {
    if (curr.props.path === displayType) {
      return curr
    }
    return acc
  }, null)
}

export const Route = props => {
  if (props.component) {
    return React.createElement(props.component, props)
  }
  return props.children
}
