import React from 'react'
import { useDispatch } from 'react-redux'

import { showDeck } from './deckSlice'

export default function DeckStringInput (props) {
  const [deck, setDeck] = React.useState('')
  const dispatch = useDispatch()

  return (
    <form method='post' action='#' id='#'>
      <textarea
        placeholder='Введите код деки'
        cols='80'
        rows='3'
        value={deck}
        onChange={updateDeck}
      />
      <br />
      <div className='deck-string-submit'>
        <button type='button' onClick={decode}>
          Раскодировать
        </button>
        <div className='deck-string-status'>{props.children}</div>
      </div>
    </form>
  )

  function updateDeck (e) {
    setDeck(e.target.value)
  }

  function decode () {
    dispatch(showDeck(deck))
  }
}
