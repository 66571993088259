import { createSlice } from '@reduxjs/toolkit'

import { replayUploadSuccess } from '../replays/replaySlice'
import { showDeck } from '../decks/deckSlice'
import { showUnit } from '../armory/armorySlice'

const initialState = {
  displayType: '/'
}

const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    setDisplayType (state, action) {
      const { displayType } = action.payload
      state.displayType = displayType
    },
    displayDeck (state) {
      state.displayType = '/deck'
    },
    displayReplay (state, action) {
      const { replay } = action.payload
      state.displayType = '/replay'
      state.replay = replay
    }
  },
  extraReducers: {
    [replayUploadSuccess]: (state, action) => {
      const { replay } = action.payload
      state.displayType = '/replay'
      state.replay = replay
    },
    [showDeck]: (state, action) => {
      if (state.displayType !== '/deck') {
        state.displayType = '/deck'
      }
    },
    [showUnit]: (state, action) => {
      if (state.displayType !== '/armory') {
        state.displayType = '/armory'
      }
    }
  }
})

export const {
  setDisplayType,
  displayDeck,
  displayReplay
} = displaySlice.actions

export default displaySlice.reducer
