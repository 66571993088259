const gql = x => x[0]

const query = gql`
  query($index: Int = -1) {
    gameData(gameVersion: $index) {
      id
      factions {
        code
        countries
      }
    }
  }
`

export default query
