import React from 'react'

import Flag from '../../component/flag'
import HashtagString from '../../component/hashtag-string'
import UnitName from '../../component/UnitName'
import { parseLeadingTag, findTag } from '@elektroplus/wgrd-hashtags'

const rangeEls = ['land', 'air', 'plane', 'ship', 'missile']

const formats = {
  boolean (value) {
    if (value) {
      return '✓'
    }
    return null
  },
  percent (value) {
    return value
  },
  default (value) {
    return value
  }
}

const weaponSpecs = [
  {
    key: 'accuracy',
    format: 'percent'
  },
  {
    key: 'stabilizer',
    format: 'percent'
  },
  {
    key: 'dispersionAngle',
    format: 'default'
  },
  {
    key: 'dispersionMaxRange',
    format: 'default'
  },
  {
    key: 'dispersionMinRange',
    format: 'default'
  },
  {
    key: 'dispersionRandom',
    format: 'default'
  },
  {
    key: 'dispersionCorrection',
    format: 'default'
  },
  {
    key: 'autoloader',
    format: 'boolean'
  },
  {
    key: 'ap',
    format: 'default'
  },
  {
    key: 'heat',
    format: 'boolean'
  },
  {
    key: 'he',
    format: 'default'
  },
  {
    key: 'aoe',
    format: 'boolean'
  },
  {
    key: 'smoke',
    format: 'boolean'
  },
  {
    key: 'heRange',
    format: 'default'
  },
  {
    key: 'suppressRange',
    format: 'default'
  },
  {
    key: 'suppress',
    format: 'default'
  },
  {
    key: 'ammo',
    format: 'default'
  },
  {
    key: 'salvoLength',
    format: 'default'
  },
  {
    key: 'aimTime',
    format: 'default'
  },
  {
    key: 'salvoReload',
    format: 'default'
  },
  {
    key: 'shotReload',
    format: 'default'
  },
  {
    key: 'multiple',
    format: 'default'
  },
  {
    key: 'noise1',
    format: 'default'
  },
  {
    key: 'noise2',
    format: 'default'
  },
  {
    key: 'isCluster',
    format: 'boolean'
  },
  {
    key: 'isRadar',
    format: 'boolean'
  },
  {
    key: 'isAntiRadar',
    format: 'boolean'
  },
  {
    key: 'supplyCost',
    format: 'default'
  },
  {
    key: 'isFireAndForget',
    format: 'boolean'
  },
  {
    key: 'maxSpeed',
    format: 'default'
  },
  {
    key: 'maxAcceleration',
    format: 'default'
  },
  {
    key: 'correctionTime',
    format: 'default'
  }
]

const armorEls = ['front', 'side', 'back', 'top']

export default function UnitInfo ({ unit, children }) {
  if (!unit) {
    return null
  }

  const weapons = unit.weapons

  let weaponData = null
  if (weapons.length > 0) {
    const types = (
      <tr>
        <td />
        {weapons.map((w, i) => (
          <td key={i} className='weapon-type'>
            {w.type}
          </td>
        ))}
      </tr>
    )
    const turrets = [
      <tr key='turretId'>
        <td className='key'>turret</td>
        {weapons.map((w, i) => (
          <td key={i}>{w.turretId}</td>
        ))}
      </tr>,
      <tr key='turretRotation'>
        <td className='key'>turretRotation</td>
        {weapons.map((w, i) => (
          <td key={i}>{w.turretRotation}</td>
        ))}
      </tr>
    ]
    const ranges = rangeEls.map((r, i) => (
      <tr key={r}>
        <td className='key'>{r}</td>
        {weapons.map((w, j) => {
          const range = w.minRange[r]
            ? w.minRange[r] + '..' + w.maxRange[r]
            : w.maxRange[r]
          return <td key={r + j}>{range}</td>
        })}
      </tr>
    ))

    const specs = weaponSpecs.map((s, i) => (
      <tr key={s.key}>
        <td className='key'>{s.key}</td>
        {weapons.map((w, j) => (
          <td key={s.key + j}>{formats[s.format](w[s.key])}</td>
        ))}
      </tr>
    ))

    weaponData = (
      <table className='weapons'>
        <thead>
          <tr>
            <th />
            {weapons.map((w, i) => (
              <th key={'wi' + i}>
                <img className='weapon' src={w.textureURL} alt={w.name} />
              </th>
            ))}
          </tr>
          <tr>
            <th />
            {weapons.map((w, i) => (
              <th key={'wn' + i}>{w.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {types}
          {turrets}
          {ranges}
          {specs}
        </tbody>
      </table>
    )
  }

  const tags = window.wgImages.tags
  const deckTypes = (
    <div className='decks'>
      {unit.deckTypes?.map((t, i) => {
        const type = parseLeadingTag(t.name)
        const typeIcon = type ? findTag(type, tags) : null
        return (
          <div key={i} className='tooltip'>
            <img className='deck-type' src={typeIcon} alt={type} />
            <div className='tooltiptext'>
              <HashtagString text={t.name} />
            </div>
          </div>
        )
      })}
    </div>
  )

  const unitData = (
    <div className='unit'>
      <img src={unit.textureURL} alt={unit.name} />
      <table className='availability'>
        <tbody>
          <tr>
            {unit.availability.map((a, i) => (
              <td key={i}>
                <Stripes count={i} />
              </td>
            ))}
          </tr>
          <tr>
            {unit.availability.map((a, i) => (
              <td key={i}>{a || ''}</td>
            ))}
          </tr>
        </tbody>
      </table>
      <table className='unit-spec'>
        <tbody>
          <tr>
            <td className='key'>maxCards</td>
            <td>{unit.maxCards}</td>
            <td />
          </tr>
          <tr>
            <td colSpan='3' className='key bold'>
              Armor
            </td>
          </tr>
          {armorEls.map((a, i) => (
            <tr key={a}>
              <td className='key'>{a}</td>
              <td>{unit.armor[a]}</td>
              <td />
            </tr>
          ))}
          <tr>
            <td className='key bold'>Health</td>
            <td>{unit.health}</td>
            <td />
          </tr>
          <tr>
            <td className='key'>size</td>
            <td>{unit.size}</td>
            <td />
          </tr>
          <tr>
            <td className='key'>ECM</td>
            <td>{unit.ecm}</td>
            <td />
          </tr>
          <tr>
            <td className='key'>stealth</td>
            <td>{unit.stealth}</td>
            <td />
          </tr>
          <tr>
            <td className='key bold'>Optics</td>
            <td className='subkey'>range</td>
            <td className='subkey'>strength</td>
          </tr>
          {rangeEls.slice(0, 4).map((r, i) => (
            <tr key={r}>
              <td className='key'>{r}</td>
              <td>{unit.optics.range[r]}</td>
              <td>{unit.optics.strength[r]}</td>
            </tr>
          ))}
          <tr>
            <td className='key' colSpan='2'>
              identifyChance
            </td>
            <td>{unit.optics.identifyChance}</td>
          </tr>
          <tr>
            <td className='key' colSpan='2'>
              identifyTime
            </td>
            <td>{unit.optics.identifyTime}</td>
          </tr>
          <tr>
            <td colSpan='3' className='key bold'>
              Mobility
            </td>
          </tr>
          <tr>
            <td className='key'>speed</td>
            <td colSpan='2'>{unit.speed + ' km/h'}</td>
          </tr>
          <tr>
            <td className='key'>altitude</td>
            <td colSpan='2'>{unit.altitude}</td>
          </tr>
          <tr>
            <td className='key'>altitudeMin</td>
            <td colSpan='2'>{unit.altitudeMin}</td>
          </tr>
          <tr>
            <td className='key'>radius</td>
            <td colSpan='2'>{unit.radius}</td>
          </tr>
          <tr>
            <td className='key'>autonomy</td>
            <td colSpan='2'>{unit.autonomy ? unit.autonomy + ' s' : ''}</td>
          </tr>
          <tr>
            <td className='key'>fuel</td>
            <td colSpan='2'>{unit.fuel ? unit.fuel + ' l' : ''}</td>
          </tr>
          <tr>
            <td colSpan='3' className='key bold'>
              Misc
            </td>
          </tr>
          <tr>
            <td className='key'>supply</td>
            <td colSpan='2'>{unit.supply ? unit.supply + ' l' : ''}</td>
          </tr>
          <tr>
            <td className='key'>prototype</td>
            <td colSpan='2'>{formats.boolean(unit.isPrototype)}</td>
            <td />
          </tr>
          <tr>
            <td className='key'>AShM target</td>
            <td colSpan='2'>{formats.boolean(unit.isAShMTarget)}</td>
            <td />
          </tr>
          <tr>
            <td className='key'>training</td>
            <td colSpan='2'>{unit.training}</td>
          </tr>
          <tr>
            <td className='key'>factory</td>
            <td colSpan='2'>{unit.factory.name}</td>
          </tr>
          <tr>
            <td className='key' colSpan='3'>
              decks
            </td>
          </tr>
        </tbody>
      </table>
      {deckTypes}
    </div>
  )

  return (
    <div className='unit-data'>
      <div className='header'>
        <Flag value={unit.country} />
        <span>{unit.cost}</span>
        <UnitName value={unit.name} />
        {children}
      </div>
      <div className='content'>
        {unitData}
        {weaponData}
      </div>
    </div>
  )
}

function Stripes ({ count }) {
  let d = 'M 2,29 l 10,-7 l 10,7'
  for (let i = 1; i <= count; i++) {
    d += ' M 2,' + (29 - i * 4) + ' l 10,-7 l 10,7'
  }
  return (
    <div className='stripes'>
      <svg width='28' height='31'>
        <path
          d={d}
          fill='none'
          stroke='#000000'
          strokeWidth={1}
          strokeOpacity={1}
        />
      </svg>
    </div>
  )
}
