const unitFragment = require('../armory/unitFragment.js')
const unitFragmentPNG = require('../armory/unitFragmentPNG.js')

const gql = x => x[0]

const query = gql`
  query($deckString: String!, $index: Int = -1) {
    gameData(gameVersion: $index) {
      id
      decodeDeckString(deckString: $deckString) {
        code
        faction
        factionCode
        nation
        nationCode
        type
        era
        costTotal
        activation
        activationSpent
        factories {
          code
          name
          unitCount
          costTotal
          activationSpent
          costs
          units {
            veterancy
            costTotal
            count
            unit {
              ...unitProps
            }
            transports {
              ...unitProps
            }
          }
        }
      }
    }
  }
`

const deckQuery = query + unitFragment
const deckQueryPNG = query + unitFragmentPNG

module.exports = { deckQuery, deckQueryPNG }
