import React from 'react'
import { useDispatch } from 'react-redux'

import { uploadReplayFile } from './replaySlice'

export default function ReplayFileUpload () {
  const [file, setFile] = React.useState()
  const dispatch = useDispatch()

  return (
    <form method='post' action='#' id='#'>
      <div>
        <label>Выберите файл реплея</label>
        <input
          type='file'
          name='file'
          className='form-control'
          onChange={chooseFile}
        />
      </div>
      <div className='col-md-6 pull-right'>
        <button
          width='100%'
          type='button'
          className='btn btn-info'
          onClick={upload}
        >
          Загрузить
        </button>
      </div>
    </form>
  )

  function chooseFile (e) {
    setFile(e.target.files[0])
  }

  function upload () {
    dispatch(uploadReplayFile(file))
  }
}
