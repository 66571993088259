import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTable } from 'react-table'

import Flag from '../../component/flag'
import UnitName from '../../component/UnitName'
import Query from '../../component/Query'
import {
  currentPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  nextPage,
  previousPage
} from '../../utils/pager'
import {
  loadUnitsSuccess,
  setPageSize,
  goToPage,
  setActiveUnit
} from './armorySlice'

import { unitQuery } from '@elektroplus/wgrd-query/armory/unitQuery.js'

function Table ({ activeRow, clickRow, columns, data, status }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({
    columns,
    data
  })

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className='units'>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={'hg' + i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <th key={'col' + j} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            function onClickRow () {
              clickRow(row.original)
            }
            const active = activeRow?.id === row.original.id ? 'active' : ''
            prepareRow(row)
            return (
              <tr
                key={'row' + i}
                {...row.getRowProps()}
                onClick={onClickRow}
                className={active}
              >
                {row.cells.map((cell, j) => {
                  return (
                    <td key={'cell' + i + '.' + j} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          <tr>
            <td colSpan='10000'>{status}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default function UnitList () {
  const dispatch = useDispatch()
  const state = useSelector(state => state.armory)
  const columns = React.useMemo(() => {
    const result = [
      { Header: 'name', accessor: 'name', Cell: UnitName },
      { Header: 'country', accessor: 'country', Cell: Flag },
      { Header: 'cost', accessor: 'cost' },
      { Header: 'factory', accessor: 'factory.name' }
    ]
    return result
  }, [])

  function clickRow (unit) {
    dispatch(setActiveUnit(unit))
  }

  function onLoad (payload) {
    dispatch(loadUnitsSuccess(payload))
  }

  function render ({ loading, error, refetch, data }) {
    const vars = loading || error ? state.loaded : state.loading
    const count = data?.gameData?.units?.count
    let status = ''
    if (loading) {
      status = (
        <>
          {'Loading... '}
          <button className='link' onClick={() => refetch()}>
            reset
          </button>
        </>
      )
    } else if (error) {
      status = (
        <>
          {error + ' '}
          <button className='link' onClick={() => refetch()}>
            reset
          </button>
        </>
      )
    } else if (!count) {
      status = 'No matches'
    } else {
      status =
        'Showing ' +
        (vars.after + 1) +
        '-' +
        Math.min(count, vars.after + vars.first) +
        ' of ' +
        count +
        ' results '
    }

    return (
      <div className='unit-list'>
        <Table
          activeRow={state.activeUnit}
          clickRow={clickRow}
          columns={columns}
          data={data?.gameData?.units?.units || []}
          status={status}
        />
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
        */}
        <div className='pagination'>
          <button
            onClick={() => dispatch(goToPage({ page: 0, count }))}
            disabled={!canPreviousPage(state, error, count)}
          >
            {'<<'}
          </button>{' '}
          <button
            onClick={() => dispatch(previousPage(state, count, goToPage))}
            disabled={!canPreviousPage(state, error, count)}
          >
            {'<'}
          </button>{' '}
          <button
            onClick={() => dispatch(nextPage(state, count, goToPage))}
            disabled={!canNextPage(state, error, count)}
          >
            {'>'}
          </button>{' '}
          <button
            onClick={() =>
              dispatch(goToPage({ page: pageCount(state, count) - 1, count }))}
            disabled={!canNextPage(state, error, count)}
          >
            {'>>'}
          </button>{' '}
        </div>
        <div className='pagination'>
          <span>
            Page{' '}
            <strong>
              {currentPage(state) + 1} of {pageCount(state, count)}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={currentPage(state) + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                dispatch(goToPage({ page, count }))
              }}
              style={{ width: '50px' }}
            />
          </span>{' '}
          <select
            value={state.pageSize}
            onChange={e => {
              dispatch(setPageSize(Number(e.target.value)))
            }}
          >
            {[10, 20, 30].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }

  return (
    <Query
      query={unitQuery}
      variables={state.loading}
      loaded={state.loaded}
      onLoad={onLoad}
      render={render}
    />
  )
}
