import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'

import store from './app/store'
import { Route, Switch, Link } from './features/display'
import MainScreen from './app/HomeScreen'
import ReplayScreen from './features/replays/ReplayScreen'
import DeckScreen from './features/decks/DeckScreen'
import ArmoryScreen from './features/armory/ArmoryScreen'
import EverythingScreen from './features/everything/EverythingScreen'

window.__APOLLO_DEVTOOLS_GLOBAL_HOOK__ = false

const cache = new InMemoryCache({
  typePolicies: {
    Unit: {
      keyFields: ['id', 'version']
    },
    Weapon: {
      keyFields: ['id', 'version']
    }
  }
})
persistCache({
  cache,
  debounce: 100,
  maxSize: 4 * 1024 * 1024,
  storage: new LocalStorageWrapper(window.localStorage)
})
  .catch(() => {
    console.log('failed to load cache')
  })
  .then(() => {
    const client = new ApolloClient({
      uri: '/graphql',
      cache
    })

    if (store.clean) {
      client.resetStore()
    }

    function RedDragon () {
      return (
        <Provider store={store}>
          <ApolloProvider client={client}>
            <div className='layout'>
              <Header />
              <Switch>
                <Route exact path='/' component={MainScreen} />
                <Route path='/replay' component={ReplayScreen} />
                <Route path='/deck' component={DeckScreen} />
                <Route path='/armory' component={ArmoryScreen} />
                <Route path='/everything' component={EverythingScreen} />
              </Switch>
            </div>
          </ApolloProvider>
        </Provider>
      )
    }

    function Header () {
      return (
        <div>
          <Link to='/'>Главная</Link>&nbsp;
          <Link to='/replay'>Реплей</Link>&nbsp;
          <Link to='/deck'>Дека</Link>&nbsp;
          <Link to='/armory'>Арсенал</Link>&nbsp;
          <Link to='/everything'>Всё</Link>
        </div>
      )
    }

    // ========================================

    ReactDOM.render(<RedDragon />, document.getElementById('root'))
  })
