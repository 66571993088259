export default function uploadFile (url, key, file) {
  const data = new FormData()
  data.append('replay', file)
  return fetch('/replay', {
    method: 'POST',
    body: data
  })
    .then(response => {
      return response.json()
    })
    .then(response => {
      const { data } = response
      return data
    })
}
