import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery, gql } from '@apollo/client'

import { showDeck } from '../../features/decks/deckSlice.js'
import { parseLeadingTag, findTag } from '@elektroplus/wgrd-hashtags'
import HashtagString from '../../component/hashtag-string'
import ReplayFileUpload from './ReplayFileUpload'

import { deckQuery } from '@elektroplus/wgrd-query/decks/decodeDeckString.js'
import mapQuery from '@elektroplus/wgrd-query/replays/map.js'
import rankQuery from '@elektroplus/wgrd-query/replays/rank.js'

export default function ReplayScreen () {
  const replay = useSelector(state => state.display.replay)

  return (
    <div>
      <ReplayFileUpload />
      <Replay replay={replay} />
    </div>
  )
}

function Replay ({ replay }) {
  if (!replay) {
    return null
  }

  const arr = [[], []]

  for (let i = 0; i < replay.players.length; i++) {
    const p = replay.players[i]
    const row = <PlayerRow player={p} key={i} />
    arr[p.PlayerAlliance].push(row)
  }

  return (
    <div>
      <label>{replay.name}</label>
      <MapHeader code={replay.raw.game.Map} />
      <table className='replay'>
        <thead>
          <tr>
            <th colSpan='4'>player</th>
            <th rowSpan='2'>deck name</th>
            <th rowSpan='2'>faction</th>
            <th rowSpan='2'>nation</th>
            <th rowSpan='2'>type</th>
            <th rowSpan='2'>era</th>
            <th rowSpan='2'>link</th>
          </tr>
          <tr>
            <th>rank</th>
            <th>place</th>
            <th>name</th>
            <th>lvl</th>
            <th colSpan='5' />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan='7' className='blue-side'>
              BLUE
            </td>
          </tr>
          {arr[0]}
          <tr>
            <td colSpan='7' className='red-side'>
              RED
            </td>
          </tr>
          {arr[1]}
        </tbody>
      </table>
    </div>
  )
}

function MapHeader ({ code }) {
  const { data } = useQuery(gql(mapQuery), {
    variables: {
      code
    }
  })
  return (
    <table>
      <tbody>
        <tr>
          <td>map:</td>
          <td>
            <HashtagString text={data?.gameData?.map?.name} />
          </td>
        </tr>
        <tr>
          <td>mode:</td>
          <td>{data?.gameData?.map?.mode}</td>
        </tr>
      </tbody>
    </table>
  )
}

function PlayerRow ({ player }) {
  let rankFragment = (
    <>
      <td />
      <td />
    </>
  )

  if (player.PlayerRank > 0) {
    rankFragment = (
      <RankRowFragment
        elo={parseFloat(player.PlayerElo)}
        place={parseFloat(player.PlayerRank)}
      />
    )
  }

  return (
    <tr>
      {rankFragment}
      <td>{player.PlayerName}</td>
      <td>{player.PlayerLevel}</td>
      <td>
        <HashtagString text={player.PlayerDeckName} />
      </td>
      <DeckRowFragment deckString={player.PlayerDeckContent} />
    </tr>
  )
}

function RankRowFragment ({ elo, place }) {
  const { error, loading, data } = useQuery(gql(rankQuery), {
    variables: {
      elo,
      place
    }
  })

  if (error || loading) {
    return (
      <>
        <td />
        <td />
      </>
    )
  }

  return (
    <>
      <td>{data?.gameData?.rank?.shortName}</td>
      <td>{place}</td>
    </>
  )
}

function DeckRowFragment ({ deckString }) {
  const { error, loading, refetch, data } = useQuery(gql(deckQuery), {
    variables: {
      deckString
    }
  })
  const deck = data?.gameData?.decodeDeckString

  if (error) {
    return (
      <td colSpan='5'>
        {error + ' '}
        <button className='link' onClick={e => refetch()}>
          reset
        </button>
      </td>
    )
  } else if (loading || !deck) {
    return (
      <td colSpan='5'>
        {'Loading... '}
        <button className='link' onClick={e => refetch()}>
          reset
        </button>
      </td>
    )
  }

  const flag = window.wgImages.flags.normal[deck.factionCode]
  const tags = window.wgImages.tags
  const n = deck.nation
  let nIcon
  if (n) {
    nIcon = window.wgImages.flags.normal[deck.nationCode]
  }
  const type = parseLeadingTag(deck.type)
  const typeIcon = type ? findTag(type, tags) : null
  const era = parseLeadingTag(deck.era)
  const eraIcon = era ? findTag(era, tags) : null

  function Link (props) {
    const dispatch = useDispatch()
    const { deck } = props

    const onClick = e => {
      e.preventDefault()
      dispatch(showDeck(deck))
    }

    return (
      <a className='deck-link' href='/deck' onClick={onClick}>
        {props.children}
      </a>
    )
  }

  return (
    <>
      <td className='icon'>
        <img className='icon' src={flag} alt={deck.faction} />
      </td>
      <td className='icon'>
        {nIcon ? <img className='icon' src={nIcon} alt={n} /> : null}
      </td>
      <td className='icon'>
        {typeIcon ? <img className='icon' src={typeIcon} alt={type} /> : null}
      </td>
      <td className='icon'>
        {eraIcon ? <img className='icon' src={eraIcon} alt={era} /> : null}
      </td>
      <td>
        <Link deck={deckString}>{deckString}</Link>
      </td>
    </>
  )
}
