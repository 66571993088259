import { createSlice } from '@reduxjs/toolkit'

import uploadFile from '../../utils/uploadFile'

const initialState = {
  uploading: false
}

const replaySlice = createSlice({
  name: 'replays',
  initialState,
  reducers: {
    startReplayUpload (state) {
      state.uploading = true
    },
    replayUploadSuccess (state, action) {
      state.uploading = false
    },
    replayUploadFailure (state, action) {
      state.uploading = false
      state.error = action.payload
    }
  }
})

export const {
  startReplayUpload,
  replayUploadSuccess,
  replayUploadFailure
} = replaySlice.actions

export const uploadReplayFile = file => async dispatch => {
  dispatch(startReplayUpload())
  try {
    const replay = await uploadFile('/replay', 'replay', file)
    dispatch(replayUploadSuccess(replay))
  } catch (err) {
    dispatch(replayUploadFailure(err.toString()))
  }
}

export default replaySlice.reducer
