import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  current: {
    index: -1,
    version: 'current'
  }
}

const slice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    setVersion (state, action) {
      state.current = action.payload
    },
    resetVersion (state) {
      state.current = initialState.current
    }
  }
})

export const { current } = initialState

export const { setVersion, resetVersion } = slice.actions

export default slice.reducer
