import equal from 'fast-deep-equal'
import { createSlice } from '@reduxjs/toolkit'

import { maybeInitiateLoading } from '../../utils/suspense'
import { setVersion } from '../version/versionSlice'

const initialState = {
  current: {
    deckString: ''
  },
  loading: {
    deckString: ''
  },
  loaded: {
    deckString: ''
  }
}

const deckSlice = createSlice({
  name: 'deck',
  initialState,
  reducers: {
    cancelLoadDeck (state, action) {
      console.log('cancel load')
      if (equal(state.current, state.loading)) {
        state.current = state.loaded
      }
      state.loading = state.loaded
      maybeInitiateLoading(state)
    },
    loadDeckSuccess (state, action) {
      state.loaded = action.payload.variables
      maybeInitiateLoading(state)
    },
    showDeck (state, action) {
      state.current.deckString = action.payload
      maybeInitiateLoading(state)
    }
  },
  extraReducers: {
    [setVersion]: (state, action) => {
      const { index } = action.payload
      state.current.index = index
      maybeInitiateLoading(state)
    }
  }
})

export const { cancelLoadDeck, loadDeckSuccess, showDeck } = deckSlice.actions

export default deckSlice.reducer
