import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setDisplayType } from './displaySlice'

export const Link = props => {
  const dispatch = useDispatch()
  const active = useSelector(state => state.display.displayType) === props.to
  const classes = 'link' + (active ? ' active' : '')

  const onClick = () => {
    dispatch(setDisplayType({ displayType: props.to }))
  }

  return (
    <button className={classes} onClick={onClick}>
      {props.children}
    </button>
  )
}

export const Switch = props => {
  const displayType = useSelector(state => state.display.displayType)
  return props.children.reduce((acc, curr) => {
    if (curr.props.path === displayType) {
      return curr
    }
    return acc
  }, null)
}

export const Route = props => {
  if (props.component) {
    return React.createElement(props.component, props)
  }
  return props.children
}
