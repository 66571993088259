import React from 'react'

import DeckStringInput from '../features/decks/DeckStringInput'
import ReplayFileUpload from '../features/replays/ReplayFileUpload'
import HashtagImg from '../component/hashtag-img'

export default function MainScreen () {
  return (
    <div>
      <ReplayFileUpload />
      <DeckStringInput />
      <HashtagImg tag='URSS' />
    </div>
  )
}
