import { configureStore } from '@reduxjs/toolkit'

import config from '../package.alias.json'
import rootReducer from './rootReducer'

const version = config.version

function loadState () {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    const state = JSON.parse(serializedState)
    if (!state.ver || state.ver.version !== version) {
      console.log('version mismatch ' + version, state.ver.version)
      return undefined
    }
    return state
  } catch {
    return undefined
  }
}

function saveState (state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch {
    // ignore write errors
  }
}

const preloadedState = loadState()

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false
    }),
  preloadedState
})

store.clean = !preloadedState

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

let t
let savingState

store.subscribe(() => {
  savingState = store.getState()
  if (t) {
    return
  }
  t = setTimeout(() => {
    t = undefined
    saveState(savingState)
    savingState = undefined
  }, 1000)
})

export default store
