const gql = x => x[0]

const query = gql`
  {
    gameData {
      id
      classes {
        id
        name
        instancesCount
        props
      }
    }
  }
`

export default query
