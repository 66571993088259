import React from 'react'
import { useQuery, gql } from '@apollo/client'
import equal from 'fast-deep-equal'

export default function Query (props) {
  const query = props.query
  const variables = props.variables
  const onLoad = props.onLoad
  const entry = useQuery(gql(query), { variables })
  const prevEntry = useQuery(gql(query), {
    fetchPolicy: 'cache-only',
    variables: props.loaded
  })
  const data = entry?.data || prevEntry?.data

  React.useEffect(() => {
    if (onLoad && entry?.data && !equal(variables, props.loaded)) {
      onLoad({ variables })
    }
  })

  return props.render({ ...entry, data })
}
