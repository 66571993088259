import React from 'react'

export default function Flag ({ value }) {
  if (!value) {
    return
  }
  const icon = window.wgImages.flags.normal[value]
  if (!icon) {
    return
  }
  return <img className='hashtag' src={icon} alt={value} />
}
