export default function gql (query, variables) {
  return fetch('/graphql', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    })
  })
    .then(response => {
      return response.json()
    })
    .then(response => {
      const { data } = response
      return data
    })
}
