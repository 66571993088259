import equal from 'fast-deep-equal'

export function maybeInitiateLoading (state, validateVariables) {
  if (isLoading(state)) {
    return
  }
  let current = JSON.parse(JSON.stringify(state.current))
  if (validateVariables) {
    current = validateVariables(current)
  }
  state.loading = current
}

export function isLoading (state) {
  return !equal(state.loading, state.loaded)
}
