import React from 'react'

import { Route, Switch, BackLink } from './EverythingRouter'
import { ClassesScreen, ClassesHeader } from './ClassesScreen'
import { OneClassScreen, OneClassHeader } from './OneClassScreen'
import { InstanceScreen, InstanceHeader } from './InstanceScreen'

export default function EverythingScreen () {
  return (
    <>
      <Header />
      <Switch>
        <Route path='/' component={ClassesScreen} />
        <Route path='/class' component={OneClassScreen} />
        <Route path='/object' component={InstanceScreen} />
      </Switch>
    </>
  )
}

function Header () {
  return (
    <div>
      <Switch>
        <Route path='/' component={ClassesHeader} />
        <Route path='/class' component={OneClassHeader} />
        <Route path='/object' component={InstanceHeader} />
      </Switch>
      <BackLink>Назад</BackLink>
    </div>
  )
}
