const unitFragment = require('./unitFragment.js')
const unitFragmentPNG = require('./unitFragmentPNG.js')

const gql = x => x[0]

const query = gql`
  query($filter: UnitFilter, $first: Int, $after: Int, $index: Int = -1) {
    gameData(gameVersion: $index) {
      id
      units(filter: $filter, first: $first, after: $after) {
        count
        units {
          ...unitProps
        }
      }
    }
  }
`

const unitQuery = query + unitFragment
const unitQueryPNG = query + unitFragmentPNG

module.exports = { unitQuery, unitQueryPNG }
