import { createSlice } from '@reduxjs/toolkit'

import { maybeInitiateLoading } from '../../utils/suspense'

import { setVersion } from '../version/versionSlice'

const initialState = {
  current: {
    filter: {},
    first: 30,
    after: 0
  },
  loading: {
    filter: {},
    first: 30,
    after: 0
  }
}

const slice = createSlice({
  name: 'armory',
  initialState,
  reducers: {
    loadUnitsSuccess (state, action) {
      state.loaded = action.payload.variables
      maybeInitiateLoading(state, validateVariables)
    },
    setPageSize (state, action) {
      let pageSize = Number(action.payload)
      if (pageSize < 5) {
        pageSize = 5
      } else if (pageSize > 30) {
        pageSize = 30
      }
      state.current.first = pageSize
      maybeInitiateLoading(state, validateVariables)
    },
    goToPage (state, action) {
      const { page, count } = action.payload
      const after = page * state.current.first
      if (!count || page < 0 || after >= count) {
        return
      }
      state.current.after = after
      maybeInitiateLoading(state, validateVariables)
    },
    filterUnitsByName (state, action) {
      const str = action.payload
      str.trim()
      if (state.current.filter.name?.fuzzy === str) {
        return
      }
      if (str.length > 0) {
        state.current.filter.name = { fuzzy: str }
      } else if (state.current.filter.name) {
        delete state.current.filter.name
      }
      state.current.after = 0
      maybeInitiateLoading(state, validateVariables)
    },
    toggleFactionFilter (state, action) {
      if (isFactionActive(state.filter, action.payload)) {
        console.log('TODO faction off', action.payload)
      } else {
        console.log('TODO faction on', action.payload)
      }
    },
    toggleCountryFilter (state, action) {
      const filter = state.current.filter
      const code = action.payload
      if (isCountryActive(filter, action.payload)) {
        if (!filter.country) {
          filter.country = { in: [code] }
        } else if (filter.country.in.length === 1) {
          filter.country = undefined
        } else {
          console.log('TODO country off', action.payload)
        }
      } else {
        console.log('TODO country on', action.payload)
      }
      state.current.after = 0
      maybeInitiateLoading(state, validateVariables)
    },
    toggleFactoryFilter (state, action) {
      const code = action.payload
      const filter = state.current.filter
      if (isFactoryActive(state, code)) {
        if (!filter.factory) {
          filter.factory = { code: { in: [code] } }
        } else if (filter.factory.code.in.length === 1) {
          filter.factory = undefined
        } else {
          console.log('TODO factory off', action.payload)
        }
      } else {
        console.log('TODO factory on', action.payload)
      }
      state.current.after = 0
      maybeInitiateLoading(state, validateVariables)
    },
    showUnit (state, action) {
      state.activeUnit = action.payload
    },
    setActiveUnit (state, action) {
      const unit = action.payload
      if (state.activeUnit?.id === unit.id) {
        state.activeUnit = undefined
      } else {
        state.activeUnit = unit
      }
    },
    setSelectedUnit (state, action) {
      const unit = action.payload
      if (state.selectedUnit?.id === unit.id) {
        state.selectedUnit = undefined
      } else {
        state.selectedUnit = unit
      }
    }
  },
  extraReducers: {
    [setVersion]: (state, action) => {
      const { index } = action.payload
      state.current.index = index
      maybeInitiateLoading(state, validateVariables)
    }
  }
})

export const {
  loadUnitsSuccess,
  setPageSize,
  goToPage,
  filterUnitsByName,
  toggleFactionFilter,
  toggleCountryFilter,
  toggleFactoryFilter,
  showUnit,
  setActiveUnit,
  setSelectedUnit
} = slice.actions

export default slice.reducer

function validateVariables (newVars) {
  const { filter } = newVars
  if (filter.name?.fuzzy && filter.name.fuzzy.length < 2) {
    delete filter.name
  }
  return newVars
}

export function isFactionActive (filter, faction) {
  if (!filter.country) {
    return true
  }
  if (!faction) {
    return false
  }
  const a = faction.countries
  const b = filter.country?.in
  if (!b) {
    return true
  }

  for (let i = 0; i < a.length; i++) {
    let found = false
    for (let j = 0; j < b.length; j++) {
      if (a[i] === b[j]) {
        found = true
        break
      }
    }
    if (found) {
      return true
    }
  }
  return false
}

export function isCountryActive (filter, code) {
  const b = filter.country?.in
  if (!b) {
    return true
  }
  let found = false
  for (let j = 0; j < b.length; j++) {
    if (code === b[j]) {
      found = true
      break
    }
  }
  return found
}

export function isFactoryActive (filter, code) {
  const b = filter.factory?.code?.in
  if (!b) {
    return true
  }
  let found = false
  for (let j = 0; j < b.length; j++) {
    if (code === b[j]) {
      found = true
      break
    }
  }
  return found
}
