import { createSlice } from '@reduxjs/toolkit'

import gql from '../../utils/gql'
import { maybeInitiateLoading } from '../../utils/suspense'

import classesQuery from '@elektroplus/wgrd-query/everything/classesQuery.js'

const initialState = {
  classes: {
    loaded: false,
    loading: true,
    classesById: []
  },
  objectsById: {},
  display: {
    type: '/'
  }
}

export function parseInstance (i) {
  const parser = {
    Class: value => value,
    Data: value => value.value,
    List: value => parseList(value, parser),
    Object: value => value
  }

  return parsePropsList(i, parser)
}

function parseList (lst, parser) {
  if (!lst.children) {
    return 'Request too shallow'
  }
  return lst.children.map(child => {
    const resolver = parser[child.value.__typename]
    if (typeof resolver !== 'function') {
      console.warn('unexpected typename', child.value.__typename)
      return JSON.stringify(child.value)
    }
    return resolver(child.value)
  })
}

function parsePropsList (lst, parser) {
  if (!lst) {
    return undefined
  }
  return lst.children.reduce((instance, child) => {
    const resolver = parser[child.value.__typename]
    if (typeof resolver !== 'function') {
      console.warn('unexpected typename', child.value.__typename)
      instance[child.name] = JSON.stringify(child.value)
      return instance
    }
    instance[child.name] = resolver(child.value)
    return instance
  }, {})
}

const routes = {
  '/': {},
  '/class': {
    handler (state, param) {
      state.cls = {
        current: {
          id: parseInt(param),
          first: 30,
          after: 0
        },
        loading: {
          id: parseInt(param),
          first: 30,
          after: 0
        }
      }
    }
  },
  '/object': {}
}

const everythingSlice = createSlice({
  name: 'everything',
  initialState,
  reducers: {
    setDisplayType (state, action) {
      const display = action.payload
      if (routes[display.type] === undefined) {
        return
      }
      if (display.type !== '/') {
        display.prev = state.display
      }
      state.display = display
      if (routes[display.type].handler) {
        routes[display.type].handler(state, display.param)
      }
    },
    setPageSize (state, action) {
      let pageSize = Number(action.payload)
      if (pageSize < 5) {
        pageSize = 5
      } else if (pageSize > 30) {
        pageSize = 30
      }
      state.cls.current.first = pageSize
      maybeInitiateLoading(state.cls)
    },
    goBack (state) {
      if (state.display.prev) {
        state.display = state.display.prev
      }
    },
    startLoadClasess (state) {
      state.classes.loading = true
    },
    loadClassesSuccess (state, action) {
      state.classes.loading = false
      state.classes.loaded = true
      action.payload.gameData.classes.map(c => {
        state.classes.classesById[c.id] = c
        return undefined
      })
    },
    loadClassesFailure (state, action) {
      state.classes.loading = false
      state.classes.loadingError = action.payload
    },
    filterClasses (state, action) {
      state.classes.filters = action.payload || []
    },
    loadInstancesSuccess (state, action) {
      state.cls.loaded = action.payload.variables
      maybeInitiateLoading(state.cls)
    },
    goToInstancesPage (state, action) {
      const { page, count } = action.payload
      const after = page * state.cls.current.first
      if (!count || page < 0 || after >= count) {
        return
      }
      state.cls.current.after = after
      maybeInitiateLoading(state.cls)
    }
  }
})

export const {
  setDisplayType,
  goBack,
  startLoadClasess,
  loadClassesSuccess,
  loadClassesFailure,
  filterClasses,
  startLoadInstances,
  loadInstancesSuccess,
  setInstancesPageSize,
  goToInstancesPage
} = everythingSlice.actions

export const loadClasses = () => async dispatch => {
  dispatch(startLoadClasess())
  try {
    const response = await gql(classesQuery, {})
    dispatch(loadClassesSuccess(response))
  } catch (err) {
    dispatch(loadClassesFailure(err.toString()))
  }
}

export const filterClassesAsync = filters => async dispatch => {
  window.setTimeout(() => dispatch(filterClasses(filters)), 0)
}

export default everythingSlice.reducer
