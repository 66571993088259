import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTable, useFilters, useSortBy } from 'react-table'

import { loadClasses, filterClassesAsync } from './everythingSlice'
import { Link } from './EverythingRouter'

function DefaultColumnFilter ({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function Table ({ data, stateReducer, filters }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => {
          return (
            <Link to='/class' param={value}>
              {value}
            </Link>
          )
        }
      },
      {
        Header: 'name',
        accessor: 'name',
        Filter: DefaultColumnFilter
      },
      {
        Header: 'instances',
        accessor: 'instancesCount',
        disableFilters: true
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      stateReducer,
      initialState: { filters }
    },
    useFilters,
    useSortBy
  )

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={'hg' + i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th key={'col' + i} {...column.getHeaderProps()}>
                <div {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                      : '  '}
                  </span>
                </div>
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr key={'row' + i} {...row.getRowProps()}>
              {row.cells.map((cell, j) => {
                return (
                  <td key={'cell' + i + '.' + j} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export function ClassesScreen () {
  const state = useSelector(state => state.everything.classes)
  const dispatch = useDispatch()
  const filtersRef = React.useRef(state.filters || [])

  function updateFilters (newState, action, prevState) {
    const { filters } = newState
    if (action.type === 'setFilter') {
      dispatch(filterClassesAsync(filters))
    }
    return newState
  }

  React.useEffect(() => {
    if (!state.loaded && !state.loadingError) {
      dispatch(loadClasses())
    }
  })

  return (
    <div>
      <Table
        data={state.classesById}
        stateReducer={updateFilters}
        filters={filtersRef.current}
      />
    </div>
  )
}

export function ClassesHeader () {
  return (
    <div>
      <span className='bold'>Всё</span>
    </div>
  )
}
