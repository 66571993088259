import React from 'react'
import { useSelector } from 'react-redux'
import { useTable, usePagination } from 'react-table'

import { gql, useQuery } from '@apollo/client'

import HashtagString from '../../component/hashtag-string'

import { parseInstance } from './everythingSlice'
import { Link } from './EverythingRouter'

import instanceQuery from '@elektroplus/wgrd-query/everything/instanceQuery.js'

export function InstanceHeader () {
  const state = useSelector(state => state.everything)
  const { cls, id } = state.display.param

  return (
    <div>
      <Link to='/'>Всё</Link>&nbsp;
      <Link to='/class' param={cls.id}>
        {cls.name}
      </Link>
      &nbsp;
      <span className='bold'>{id}</span>
    </div>
  )
}

export function InstanceScreen () {
  const state = useSelector(state => state.everything)
  const id = Number.parseInt(state.display.param.id)
  const { data, loading, error } = useQuery(gql(instanceQuery), {
    variables: { id }
  })
  const instance = parseInstance(data?.gameData?.object)

  if (loading || !instance) {
    return 'Loading ...'
  }

  if (error) {
    return error.toString()
  }

  return (
    <table>
      <tbody>
        {Object.keys(instance).map(key => {
          const value = instance[key]
          let data
          if (typeof value === 'object') {
            if (Array.isArray(value)) {
              data = <Table data={value} pageSize={30} />
            } else {
              data = Cell({ value })
            }
          } else {
            data = value
          }

          return (
            <tr key={key}>
              <td>{key}</td>
              <td>{data}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function Cell (props) {
  const { value } = props
  let data = JSON.stringify(value)

  if (typeof value !== 'object') {
    return <HashtagString text={value.toString()} />
  }

  if (Array.isArray(value)) {
    if (value.length === 1) {
      return <Cell value={value[0]} />
    } else if (value.length === 2) {
      return (
        <>
          <Cell key={0} value={value[0]} />: <Cell key={1} value={value[1]} />
        </>
      )
    } else {
      return ['[']
        .concat(
          value.map((v, i) => (
            <React.Fragment key={i}>
              {i > 0 ? ', ' : ''}
              <Cell value={v} />
            </React.Fragment>
          ))
        )
        .concat(']')
    }
  }

  if (value.__typename === 'Object') {
    const { cls, id } = value
    data = (
      <Link to='/object' param={{ cls, id }}>
        {cls.name + ' ' + id}
      </Link>
    )
  } else if (value.__typename === 'Class') {
    data = (
      <Link to='/class' param={value.id}>
        {value.name}
      </Link>
    )
  }

  return data
}

function Table ({ data, pageSize: initPageSize }) {
  const columns = React.useMemo(
    () => [
      {
        accessor: r => r,
        Cell,
        id: 'data'
      }
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initPageSize }
    },
    usePagination
  )

  let paginator = null
  if (pageCount > 1) {
    paginator = (
      <div className='pagination'>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    )
  }

  return (
    <>
      <table {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={'row' + i} {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td key={'cell' + i + '.' + j} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {paginator}
    </>
  )
}
