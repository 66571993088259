const gql = x => x[0]

const query = gql`
  query($code: String!) {
    gameData {
      id
      map(code: $code) {
        name
        mode
      }
    }
  }
`

module.exports = query
