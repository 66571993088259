import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Flag from '../../component/flag'
import Query from '../../component/Query'
import {
  toggleFactionFilter,
  toggleCountryFilter,
  isFactionActive,
  isCountryActive
} from './armorySlice'

import query from '@elektroplus/wgrd-query/armory/factionsQuery.js'

export default function CountryFilter (props) {
  const dispatch = useDispatch()
  const state = useSelector(state => state.armory)
  const filter = state.current.filter
  const index = state.current.index

  function factionClick (f) {
    return () => dispatch(toggleFactionFilter(f))
  }

  function countryClick (c) {
    return () => dispatch(toggleCountryFilter(c))
  }

  function cf (f) {
    return isFactionActive(filter, f) ? '' : 'inactive'
  }

  function cc (c) {
    return isCountryActive(filter, c) ? '' : 'inactive'
  }

  const render = ({ data }) => {
    if (!data?.gameData?.factions) {
      return null
    }
    return (
      <table className='country-filter'>
        <tbody>
          {data.gameData.factions?.map(f => (
            <tr key={f.code}>
              <td onClick={factionClick(f)} className={cf(f)}>
                <Flag value={f.code} />
              </td>
              {f.countries.map(c => (
                <td key={c} onClick={countryClick(c)} className={cc(c)}>
                  <Flag value={c} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  return <Query query={query} variables={{ index }} render={render} />
}
