import instanceFragment from './instanceFragment.js'
const gql = x => x[0]

const query =
  gql`
    query($id: Int!) {
      gameData {
        id
        object(id: $id) {
          ...InstanceChildren
        }
      }
    }
  ` + instanceFragment

export default query
