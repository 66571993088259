module.exports.findHashtags = (text, tags) => {
  let hasHashTag = false
  const fragments = []
  let prevStart = 0
  let start = 0

  for (let i = 0; i <= text.length; i++) {
    const char = text[i] || ' '
    if (isCharAlphaNumeric(char.charCodeAt(0))) {
      continue
    }

    if (hasHashTag) {
      hasHashTag = false
      const tag = text.substring(start + 1, i)
      if (findTag(tag, tags)) {
        if (start > prevStart) {
          fragments.push({ str: text.substring(prevStart, start) })
        }
        fragments.push({ tag })
        prevStart = i
      }
    }

    if (char === '#') {
      hasHashTag = true
      start = i
    }
  }

  if (text.length > prevStart) {
    fragments.push({ str: text.substring(prevStart) })
  }

  return fragments
}

module.exports.parseLeadingTag = text => {
  if (text[0] !== '#') {
    return null
  }
  const space = text.indexOf(' ')
  const tag = space === -1 ? text.substring(1) : text.substring(1, space)
  return tag
}

function findTag (tag, tags) {
  const cmd = tags.find(e => e.tag === tag)
  if (!cmd) {
    return null
  }
  return cmd.png
}
module.exports.findTag = findTag

function isCharAlphaNumeric (code) {
  if (
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123)
  ) {
    // lower alpha (a-z)
    return false
  }
  return true
}
