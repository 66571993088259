import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTable } from 'react-table'

import HashtagString from '../../component/hashtag-string'
import Query from '../../component/Query'
import {
  currentPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  nextPage,
  previousPage
} from '../../utils/pager'

import {
  setInstancesPageSize,
  goToInstancesPage,
  loadInstancesSuccess,
  parseInstance
} from './everythingSlice'
import { Link } from './EverythingRouter'

import classQuery from '@elektroplus/wgrd-query/everything/classQuery.js'

export function OneClassScreen () {
  const dispatch = useDispatch()
  const state = useSelector(state => state.everything.cls)

  return (
    <Query
      query={classQuery}
      variables={state?.loading}
      loaded={state?.loaded}
      onLoad={onLoad}
      render={render}
    />
  )

  function onLoad (payload) {
    dispatch(loadInstancesSuccess(payload))
  }

  function render ({ loading, error, refetch, data }) {
    const vars = loading || error ? state.loaded : state.loading
    const count = data?.gameData?.cls?.instancesCount
    let status = ''
    if (loading) {
      status = (
        <>
          {'Loading... '}
          <button className='link' onClick={e => refetch()}>
            reset
          </button>
        </>
      )
    } else if (error) {
      status = (
        <>
          {error + ' '}
          <button className='link' onClick={e => refetch()}>
            reset
          </button>
        </>
      )
    } else if (!count) {
      status = 'No matches'
    } else {
      status =
        'Showing ' +
        (vars.after + 1) +
        '-' +
        Math.min(count, vars.after + vars.first) +
        ' of ' +
        count +
        ' results '
    }

    if (!data?.gameData?.cls) {
      return status
    }

    return (
      <>
        <Table cls={data?.gameData?.cls} status={status} />
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
        */}
        <div className='pagination'>
          <button
            onClick={() => dispatch(goToInstancesPage({ page: 0, count }))}
            disabled={!canPreviousPage(state, error, count)}
          >
            {'<<'}
          </button>{' '}
          <button
            onClick={() =>
              dispatch(previousPage(state, count, goToInstancesPage))}
            disabled={!canPreviousPage(state, error, count)}
          >
            {'<'}
          </button>{' '}
          <button
            onClick={() => dispatch(nextPage(state, count, goToInstancesPage))}
            disabled={!canNextPage(state, error, count)}
          >
            {'>'}
          </button>{' '}
          <button
            onClick={() =>
              dispatch(
                goToInstancesPage({ page: pageCount(state, count) - 1, count })
              )}
            disabled={!canNextPage(state, error, count)}
          >
            {'>>'}
          </button>{' '}
        </div>
        <div className='pagination'>
          <span>
            Page{' '}
            <strong>
              {currentPage(state) + 1} of {pageCount(state, count)}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={currentPage(state) + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                dispatch(goToInstancesPage({ page, count }))
              }}
              style={{ width: '50px' }}
            />
          </span>{' '}
          <select
            value={state.pageSize}
            onChange={e => {
              dispatch(setInstancesPageSize(Number(e.target.value)))
            }}
          >
            {[10, 20, 30].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    )
  }
}

export function OneClassHeader () {
  const state = useSelector(state => state.everything)
  const classId = state.display.param
  const name = state.classes.classesById[classId].name
  return (
    <div>
      <Link to='/'>Всё</Link>&nbsp;
      <span className='bold'>{name}</span>
    </div>
  )
}

function Table ({ cls, status }) {
  const columns = React.useMemo(() => {
    const result = [
      {
        Header: 'id',
        accessor: 'id',
        Cell: ({ value: id }) => {
          return (
            <Link
              to='/object'
              param={{ cls: { name: cls.name, id: cls.id }, id }}
            >
              {id}
            </Link>
          )
        }
      }
    ]

    return result.concat(
      cls.props.map(p => {
        return {
          Header: p,
          accessor: p,
          Cell
        }
      })
    )
  }, [cls.props, cls.name, cls.id])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({
    columns,
    data: (cls?.instances || []).map(i => parseInstance(i))
  })

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={'hg' + i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <th key={'col' + j} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr key={'row' + i} {...row.getRowProps()}>
                {row.cells.map((cell, j) => {
                  return (
                    <td key={'cell' + i + '.' + j} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
          <tr>
            <td colSpan='10000'>{status}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

function Cell ({ value }) {
  if (value === undefined) {
    return ''
  } else if (Array.isArray(value)) {
    return '[ ]'
  }
  return <HashtagString text={value.toString()} />
}
