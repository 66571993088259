import React from 'react'

const imgMap = new Map()
window.wgImages.tags.map(i => imgMap.set(i.tag, i.png))

export default function HashtagImg ({ tag }) {
  if (!imgMap.has(tag)) {
    return null
  }

  return <img className='hashtag' src={imgMap.get(tag)} alt={'#' + tag} />
}
