import React from 'react'

import { findHashtags } from '@elektroplus/wgrd-hashtags'
import HashtagImg from '../component/hashtag-img'

export default function HashtagString ({ text }) {
  if (typeof text === 'number') {
    return <span className='number'>{text.toString()}</span>
  } else if (typeof text !== 'string') {
    return null
  }

  const fragments = findHashtags(text, window.wgImages.tags)

  return fragments.map((f, i) => {
    if (f.tag) {
      return <HashtagImg key={'frag' + i} tag={f.tag} />
    } else {
      return <React.Fragment key={'frag' + i}>{f.str}</React.Fragment>
    }
  })
}
