import instanceFragment from './instanceFragment.js'
const gql = x => x[0]

const query =
  gql`
    query($id: Int!, $first: Int!, $after: Int!) {
      gameData {
        id
        cls(id: $id) {
          id
          name
          instancesCount
          props
          instances(first: $first, after: $after) {
            ...InstanceChildren
          }
        }
      }
    }
  ` + instanceFragment

export default query
