const gql = x => x[0]

const query = gql`
  query($elo: Float!, $place: Int!) {
    gameData {
      id
      rank(elo: $elo, place: $place) {
        shortName
      }
    }
  }
`

module.exports = query
