const gql = x => x[0]

const query = gql`
  fragment InstanceChildren on Object {
    cls {
      id
      name
    }
    children {
      name
      value {
        __typename
        ... on Data {
          value
        }
        ... on Object {
          id
          cls {
            id
            name
          }
        }
        ... on Class {
          id
          name
        }
        ... on List {
          children {
            name
            value {
              __typename
              ... on Data {
                value
              }
              ... on Object {
                id
                cls {
                  id
                  name
                }
              }
              ... on Class {
                id
                name
              }
              ... on List {
                children {
                  name
                  value {
                    __typename
                    ... on Data {
                      value
                    }
                    ... on Object {
                      id
                      cls {
                        id
                        name
                      }
                    }
                    ... on Class {
                      id
                      name
                    }
                    ... on List {
                      children {
                        name
                        value {
                          __typename
                          ... on Data {
                            value
                          }
                          ... on Object {
                            id
                            cls {
                              id
                              name
                            }
                          }
                          ... on Class {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default query
