const gql = x => x[0]

const query = gql`
  {
    gameVersions {
      index
      version
    }
  }
`

export default query
