import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Query from '../../component/Query'

import { current, setVersion } from './versionSlice'

import versionsQuery from '@elektroplus/wgrd-query/version/versionsQuery.js'

export default function VersionPicker () {
  const dispatch = useDispatch()
  const state = useSelector(state => state.version.current)

  function render ({ loading, error, refetch, data }) {
    const versions = data?.gameVersions
    return (
      <select
        value={state.index}
        onChange={e => {
          const i = Number(e.target.value)
          if (i >= 0) {
            return dispatch(setVersion(versions[i]))
          }
          dispatch(setVersion(current))
        }}
      >
        {versions?.map(v => (
          <option key={v.index} value={v.index}>
            {v.version}
          </option>
        ))}
        <option key={current.index} value={current.index}>
          {current.version}
        </option>
      </select>
    )
  }

  return <Query query={versionsQuery} render={render} />
}
