import React from 'react'

import HashtagString from './hashtag-string'

export default function Name ({ value }) {
  return (
    <div className='name'>
      <HashtagString text={value} />
    </div>
  )
}
