const gql = x => x[0]

const query = gql`
  query {
    gameData {
      id
      factories {
        code
        name
      }
    }
  }
`

export default query
