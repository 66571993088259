import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import HashtagString from '../../component/hashtag-string'
import Query from '../../component/Query'
import VersionPicker from '../version/VersionPicker'

import { cancelLoadDeck, loadDeckSuccess } from './deckSlice'
import DeckStringInput from './DeckStringInput'
import Unit from './Unit'

import { deckQuery } from '@elektroplus/wgrd-query/decks/decodeDeckString.js'

export default function DeckScreen () {
  const dispatch = useDispatch()
  const state = useSelector(state => state.deck)

  return (
    <Query
      query={deckQuery}
      variables={state.loading}
      loaded={state.loaded}
      onLoad={payload => dispatch(loadDeckSuccess(payload))}
      render={props => <Deck {...props} />}
    />
  )
}

function Deck (props) {
  const dispatch = useDispatch()
  const { error, loading, refetch, data } = props
  const deck = data?.gameData?.decodeDeckString

  let status = null
  if (error) {
    status = (
      <>
        {error + ' '}
        <span className='link' onClick={e => dispatch(cancelLoadDeck())}>
          reset
        </span>
      </>
    )
  } else if (loading) {
    status = (
      <>
        {'Loading... '}
        <span className='link' onClick={e => refetch()}>
          reset
        </span>
      </>
    )
  }

  const keys = ['code', 'faction', 'nation', 'type', 'era', 'costTotal']
  let header = null
  let factories = null
  if (deck) {
    header = (
      <table>
        <tbody>
          {keys.map(k => (
            <tr key={k}>
              <td>{k}</td>
              <td>
                <HashtagString text={deck[k]} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
    factories = (
      <table className='factories'>
        <tbody>
          {deck.factories.map(f => {
            return (
              <tr key={f.name}>
                <td>
                  <div>{f.name}</div>
                  <div>{f.unitCount} units</div>
                  <div>{f.costTotal} points</div>
                </td>
                {f.units.map((u, i) => (
                  <td key={f.name + i} className='unit'>
                    <Unit factory={f} unit={u} />
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <div className='deck'>
      <DeckStringInput>{status}</DeckStringInput>
      <span>
        version: <VersionPicker />
      </span>
      {header}
      {factories}
    </div>
  )
}
