import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import VersionPicker from '../version/VersionPicker'

import { setSelectedUnit } from './armorySlice'
import CountryFilter from './CountryFilter'
import FactoryFilter from './FactoryFilter'
import UnitFilter from './UnitFilter'
import UnitInfo from './UnitInfo'
import UnitList from './UnitList'

export default function ArmoryScreen () {
  const dispatch = useDispatch()
  const state = useSelector(state => state.armory)

  function select () {
    dispatch(setSelectedUnit(state.activeUnit))
  }

  function deselect () {
    dispatch(setSelectedUnit(state.selectedUnit))
  }

  return (
    <div className='armory'>
      <span>
        version: <VersionPicker />
      </span>
      <CountryFilter />
      <FactoryFilter />
      <UnitFilter />
      <div className='unit-container'>
        <UnitList />
        <UnitInfo unit={state.activeUnit}>
          <span className='link' onClick={select}>
            {'>>'}
          </span>
        </UnitInfo>
        <UnitInfo unit={state.selectedUnit}>
          <span className='link' onClick={deselect}>
            x
          </span>
        </UnitInfo>
      </div>
    </div>
  )
}
