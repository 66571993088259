import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Query from '../../component/Query'

import { toggleFactoryFilter, isFactoryActive } from './armorySlice'

import query from '@elektroplus/wgrd-query/armory/factoriesQuery.js'

export default function FactoryFilter (props) {
  const dispatch = useDispatch()
  const state = useSelector(state => state.armory)
  const filter = state.current.filter

  function factoryClick (f) {
    return () => dispatch(toggleFactoryFilter(f.code))
  }

  function cf (f) {
    return isFactoryActive(filter, f.code) ? '' : 'inactive'
  }

  function render ({ data }) {
    if (!data?.gameData?.factories) {
      return null
    }
    return (
      <table className='factory-filter'>
        <tbody>
          <tr>
            {data.gameData?.factories?.map(f => (
              <td key={f.code} onClick={factoryClick(f)} className={cf(f)}>
                {f.name}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    )
  }

  return <Query query={query} variables={{}} render={render} />
}
